@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap");
.home {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: home-back-color-change 20s infinite;
  background-image: url(1.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: orange;
  user-select: none;
}
.home_title {
  font-size: 2.5em;
  font-family: "Raleway", sans-serif;
}
.home_name {
  font-family: "Raleway-semibold";
}
@keyframes home-back-color-change {
  0% {
    background-color: rgb(7, 102, 7);
  }
  20% {
    background-color: rgb(78, 59, 88);
  }
  40% {
    background-color: rgb(44, 5, 70);
  }
  60% {
    background-color: rgb(207, 120, 7);
  }
  80% {
    background-color: rgb(163, 13, 13);
  }
  100% {
    background-color: rgb(7, 102, 7);
  }
}
#home {
  background-blend-mode: difference;
}

.typingEffect {
  font-size: 2em;
  font-family: "Codystar", cursive;
  color: whitesmoke;
  margin-bottom: 1em;
}
@media screen and (max-width: 768px) {
  .typingEffect {
    display: none;
  }
}
@media screen and (max-width: 599px) {
  #tsparticles {
    display: none;
  }
}
