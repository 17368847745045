/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
.portfolio {
  min-height: 90vh;
}
.portfolio-tiltle {
  font-family: "Oswald", sans-serif;
  color: rgb(202, 192, 200);
  /* margin-bottom: 2em; */
  /* transform: rotate(-3deg); */
  padding-top: 5em;
}
.portfolio-projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-columns: repeat(auto-fill, minmax(15em, 1fr)); */
  grid-gap: 0.5em;
}
.card {
  max-width: 22em;
  opacity: 1;
  transition: transform 1s, opacity 2s;
  clip-path: polygon(
    25% 0,
    75% 0,
    100% 15%,
    100% 85%,
    75% 100%,
    25% 100%,
    0 85%,
    0 15%
  );
}
.card-text-paragraph {
  text-align: left;
}
.card-tooltip-header {
  animation: contact-text-color-change 10s infinite;
}
.portfolio-projects:hover > .card:hover {
  transform: scale(1.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 10;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.portfolio-projects:hover > .card:not(:hover) {
  opacity: 0.7;
  transform: scale(0.8);
}

.portfolio-carousel {
  transition: transform 1s;
  transform: scale(0.6);
  clip-path: polygon(7% 0, 93% 0, 100% 50%, 93% 100%, 7% 100%, 0 50%);
}
/* .carousel-caption {
  color: orange !important;
} */
.portfolio-carousel:hover {
  transform: scale(1) translate(1%, 1%);
}
.card-body {
  /* font-family: "League Spartan", sans-serif; */
  font-family: "Abel", sans-serif;
}
.card-title {
  color: orange;
}
.portfolio-tiltle_responsive_on {
  display: none;
}

@media screen and (max-width: 1999px) {
  .portfolio-carousel:hover {
    transform: scale(0.7) translate(1%, 1%);
  }
  .portfolio-carousel {
    transition: transform 1s;
    transform: scale(0.4);
  }
  .portfolio-projects {
    transform: scale(0.8);
  }
  .portfolio-upper-div {
    padding-top: 5em;
    margin-bottom: 1em;
  }
  .card-body {
    font-size: 1.3em;
  }
}

@media screen and (max-width: 999px) {
  .portfolio-projects {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 599px) {
  .portfolio-projects {
    grid-template-columns: 1fr;
    margin-bottom: unset;
  }
  .portfolio-tiltle_responsive_off {
    display: none;
  }
  .portfolio-tiltle_responsive_on {
    display: block;
    padding-bottom: 3em;
  }
  .porfolio-stars {
    display: none;
  }
}
