.contact {
  /* background-color: rgb(56, 51, 56); */
  min-height: 100vh;
  color: orange;
}
.contact > h1 {
  color: orange;
}

.form-control {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid grey !important;
  border-radius: unset !important;
  color: orange !important;
}
.form-control:focus {
  border-bottom: 2px solid orange !important;
}
.form-textarea {
  height: 5.65em;
}
.contact-form-div {
  background-color: rgb(56, 51, 56);
  z-index: 1;
  opacity: 95%;
  border-radius: 2%;
  margin-top: 5em;
  margin-bottom: 1em;
}
.contact-typingEffect-div {
  display: flex;
  justify-content: start;
  padding-top: 5em;
}
.contact-typingEffect {
  animation: contact-text-color-change 20s infinite;
  font-size: large;
}
