.about {
  background-color: rgb(247, 248, 237);
  min-height: 100vh;
  font-family: "Roboto Condensed", sans-serif;
  margin-bottom: 3em;
  /* opacity: 95%; */
}
a {
  text-decoration: none;
}
/* .img-thumbnail {
  max-width: 13em !important;
} */
.about-photo {
  max-width: 18em;
  margin-bottom: 1em;
  clip-path: polygon(
    21% 20%,
    20% 21%,
    19% 22%,
    18% 23%,
    17% 24%,
    16% 28%,
    16% 30%,
    16% 33%,
    17% 35%,
    18% 38%,
    19% 40%,
    20% 42%,
    22% 45%,
    24% 48%,
    25% 50%,
    28% 55%,
    29% 57%,
    30% 59%,
    30% 61%,
    28% 64%,
    10% 67%,
    2% 69%,
    2% 85%,
    /* 15% 95%, */ 30% 98%,
    /* bottom */ 70% 98%,
    /* 85% 95%, */ 98% 85%,
    98% 98%,
    98% 69%,
    80% 63.5%,
    79% 63.2%,
    78% 63%,
    77% 62.5%,
    74% 61%,
    73% 60%,
    72.5% 58%,
    72% 55%,
    72% 50%,
    73% 47%,
    73.5% 46.5%,
    74% 46%,
    75% 44%,
    76% 43%,
    77% 41%,
    77% 40%,
    77.5% 38%,
    77.5% 37%,
    77% 35%,
    78.5% 33%,
    78% 29%,
    77% 26%,
    75% 24%,
    70% 19.5%,
    65% 17%,
    55% 14.5%,
    50% 14%,
    45% 14%,
    40% 14%,
    35% 15%,
    30% 16%
  );
}
.about-p {
  text-align: left;
}

.about-frontend {
  grid-area: frontend;
}
.about-backend {
  grid-area: backend;
}
.about-soft-skills {
  grid-area: soft-skills;
}
.about-qualifications {
  grid-area: qualifications;
}
.about-key-skills {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 777px) {
  .about-key-skills {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 575px) {
  .about-key-skills {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 400px) {
  .about-key-skills {
    grid-template-columns: 1fr;
  }
}
